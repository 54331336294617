import { faArrowDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC, useEffect, useState } from 'react'

interface ScrollIndicatorProps {
  href?: string
}

export const ScrollIndicator: FC<ScrollIndicatorProps> = ({ href }) => {
  const [position, setPosition] = useState(5)

  useEffect(() => {
    setInterval(() => {
      setPosition((p) => (p === 1 ? 8 : p - 1))
    }, 500)
  }, [])

  return (
    <a
      className={`text-gray-300 absolute bottom-${position} left-1/2`}
      style={{ transform: 'translate(-50%, -50%)' }}
      href={href}
    >
      <FontAwesomeIcon icon={faArrowDown} className="inline" />
      <p className="inline ml-1">scroll down</p>
    </a>
  )
}
