import { faGithub, faGitlab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC } from 'react'

interface CopyRightProps {
  isLink?: boolean
  openSourceRepository?: string
}

export const CopyRight: FC<CopyRightProps> = ({
  isLink = true,
  openSourceRepository,
}) => (
  <>
    <a
      href={isLink ? 'https://brecht.pallemans.com' : undefined}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src={require('assets/brecht_pallemans_logo.png')}
        className="w-5 h-5 inline-block align-middle mr-3"
      />
      <span className="text-white inline-block align-middle mr-1">
        2022 © Created by
      </span>
      <span className="text-[#B42020] font-bold inline-block align-middle">
        Brecht Pallemans
      </span>
      <span className="text-white inline-block align-middle mr-1">.</span>
      {!openSourceRepository && (
        <span className="text-white inline-block align-middle">
          All rights reserved.
        </span>
      )}
    </a>
    {openSourceRepository && (
      <a href={openSourceRepository} target="_blank" rel="noopener noreferrer">
        <span className="text-white inline-block align-middle mr-1">
          View this website on
        </span>
        <FontAwesomeIcon
          icon={openSourceRepository.includes('gitlab') ? faGitlab : faGithub}
          className="text-white inline-block align-middle"
        />
      </a>
    )}
  </>
)
