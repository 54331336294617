import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC } from 'react'
import FullScreen from 'react-div-100vh'
import { useTranslation } from 'react-i18next'

export const NotFound: FC = () => {
  const { t } = useTranslation()
  return (
    <FullScreen className="bg-neutral flex flex-col justify-center">
      <div className="flex justify-center">
        <FontAwesomeIcon
          icon={faQuestionCircle}
          size="4x"
          className="m-5 text-primary"
        />
      </div>
      <h4 className="mb-5 px-4 text-neutral-content text-xl font-bold text-center">
        {t('notFound.title')}
      </h4>
      <p className="mb-5 px-4 text-gray-500 text-xs text-center">
        {t('notFound.subTitle')}
      </p>
    </FullScreen>
  )
}
