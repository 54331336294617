import classNames from 'classnames'
import { FC, useState } from 'react'

interface CardProps {
  className?: string
  content: string
  description?: string
  id?: string
  imageSrc?: string
  isOpenByDefault?: boolean
  subTitle?: string
  title?: string
  titleAccent?: string
  titleSize?: number
}

export const Card: FC<CardProps> = ({
  className,
  content,
  description,
  id,
  imageSrc,
  isOpenByDefault = false,
  subTitle,
  title,
  titleAccent,
  titleSize = 1,
}) => {
  const [isOpen, setOpen] = useState(isOpenByDefault)

  return (
    <div
      id={id}
      className={classNames(
        'z-10 py-2 md:py-4 px-4 md:px-6 rounded-xl w-full lg:w-3/4 xl:w-2/3',
        imageSrc && 'md:flex lg:flex xl:flex',
        className
      )}
    >
      {imageSrc && (
        <div>
          <img src={imageSrc} className="rounded-xl mx-auto mb-3 w-52 mt-2" />
        </div>
      )}
      <div className={classNames('flex-1', imageSrc && 'md:ml-8')}>
        <div
          className={classNames(
            'text-neutral-content  text-center md:text-left',
            titleSize === 2
              ? 'text-2xl md:text-3xl lg:text-4xl'
              : 'text-xl md:text-2xl lg:text-3xl'
          )}
        >
          {title} <h1 className="text-primary inline-block">{titleAccent}</h1>
        </div>
        {subTitle && (
          <h1
            className={classNames(
              'text-neutral-content text-center md:text-left',
              titleSize === 2
                ? 'text-2xl md:text-3xl lg:text-4xl'
                : 'text-xl md:text-2xl lg:text-3xl'
            )}
          >
            {subTitle}
          </h1>
        )}
        {description && (
          <h2 className="text-gray-300 pt-2  text-center md:text-left">
            {description}
          </h2>
        )}
        <p
          className="text-neutral-content text-justify pt-2"
          onClick={() => setOpen(true)}
        >
          {!isOpen && content.length > 511
            ? content.substring(0, 511).concat('... READ MORE')
            : content}
        </p>
      </div>
    </div>
  )
}
