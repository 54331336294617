import './i18n'
import { Suspense } from 'react'
import { Loading } from 'components/base/Loading'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { routes } from 'config'
import { Index } from 'pages/Index'
import { NotFound } from 'pages/NotFound'

function App() {
  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path={routes.default} element={<Index />} />
          <Route path={routes.wildCard} element={<NotFound />} />
        </Routes>
      </Suspense>
    </Router>
  )
}

export default App
