import { FC } from 'react'

interface SkillCardProps {
  content: string
  id?: string
  imageSrc?: string
  title?: string
}

export const SkillCard: FC<SkillCardProps> = ({
  content,
  id,
  imageSrc,
  title,
}) => (
  <div id={id} className="py-2 md:py-4 px-4 md:px-6 w-full md:w-1/2 lg:w-1/3">
    {imageSrc && (
      <img src={imageSrc} className="mx-auto mb-3 h-20 md:h-28 mt-2" />
    )}
    <h1 className="text-neutral-content  text-center text-xl md:text-2xl lg:text-3xl">
      {title}
    </h1>
    <p className="text-neutral-content text-justify pt-2">{content}</p>
  </div>
)
