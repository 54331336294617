import { FC } from 'react'
import FullScreen from 'react-div-100vh'
import { useTranslation } from 'react-i18next'
import { Credit } from 'components/base/Credit'
import { Card } from 'components/base/Card'
import { ScrollIndicator } from 'components/base/ScrollIndicator'
import { SkillCard } from 'components/base/SkillCard'
import { Devider } from 'components/base/Devider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'

export const Index: FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <div
        id="about"
        className="min-h-full flex flex-col bg-neutral justify-between w-full p-2 md:p-12 lg:px-20"
      >
        <div className="w-full text-right p-4 lg:p-0">
          <a
            href="https://www.linkedin.com/in/brecht-pallemans/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              icon={faLinkedin}
              className="text-neutral-content hover:text-[#0072B1] inline-block text-3xl mr-4"
            />
          </a>
          <a
            href="mailto:brecht.pallemans@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              icon={faEnvelope}
              type={'regular'}
              className="text-neutral-content hover:text-primary inline-block text-3xl"
            />
          </a>
        </div>
        <div className="w-full flex  justify-between items-center">
          <Card
            id="introduction"
            titleSize={2}
            isOpenByDefault
            imageSrc={require('assets/portrait.jpeg')}
            title={t('about.title')}
            titleAccent={t('about.titleAccent')}
            subTitle={t('about.subTitle')}
            content={t('about.content')}
          />
          <div className="hidden lg:block">
            <ul className="text-right">
              <li className="btn-link">
                <a
                  href="#skills"
                  className="text-neutral-content text-xl md:text-2xl"
                >
                  {t('skills.id')}
                </a>
              </li>
              <li className="mt-3 btn-link">
                <a
                  href="#career"
                  className="text-neutral-content text-xl md:text-2xl"
                >
                  {t('career.id')}
                </a>
              </li>
              <li className="mt-3 btn-link">
                <a
                  href="#projects"
                  className="text-neutral-content text-xl md:text-2xl"
                >
                  {t('projects.id')}
                </a>
              </li>
              <li className="mt-3 btn-link">
                <a
                  href="#education"
                  className="text-neutral-content text-xl md:text-2xl"
                >
                  {t('education.id')}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="h-8" />
        <ScrollIndicator href="#skills" />
      </div>
      <Devider />
      <div
        id="skills"
        className="min-h-full flex flex-col bg-neutral justify-between w-full p-2 md:p-12 lg:px-20"
      >
        <div className="w-full text-center">
          <h1 className="text-neutral-content mb-2 text-2xl md:text-3xl lg:text-4xl">
            {t('skills.id')}
          </h1>
        </div>
        <div className="flex justify-between w-full flex-wrap">
          <SkillCard
            imageSrc={require('assets/frontend.png')}
            title={t('skills.frontend.title')}
            content={t('skills.frontend.content')}
          />
          <SkillCard
            imageSrc={require('assets/backend.png')}
            title={t('skills.backend.title')}
            content={t('skills.backend.content')}
          />
          <SkillCard
            imageSrc={require('assets/operations.png')}
            title={t('skills.operations.title')}
            content={t('skills.operations.content')}
          />
        </div>
        <Card
          id="skills-content"
          className="mx-auto"
          isOpenByDefault
          content={t('skills.content')}
        />
      </div>
      <Devider />
      <div
        id="career"
        className="min-h-full flex flex-col bg-neutral justify-between w-full p-2 md:p-12 lg:px-20"
      >
        <div className="w-full text-center">
          <h1 className="text-neutral-content mb-2 text-2xl md:text-3xl lg:text-4xl">
            {t('career.id')}
          </h1>
        </div>
        <Card
          id="career-appman"
          className="mx-auto"
          title={t('career.appman.title')}
          titleAccent={t('career.appman.titleAccent')}
          description={t('career.appman.description')}
          content={t('career.appman.content')}
        />
        <Card
          id="career-codium"
          className="mx-auto"
          title={t('career.codium.title')}
          titleAccent={t('career.codium.titleAccent')}
          description={t('career.codium.description')}
          content={t('career.codium.content')}
        />
        <Card
          id="career-pleegzorg"
          className="mx-auto"
          title={t('career.pleegzorg.title')}
          titleAccent={t('career.pleegzorg.titleAccent')}
          description={t('career.pleegzorg.description')}
          content={t('career.pleegzorg.content')}
        />
      </div>
      <Devider />
      <div
        id="projects"
        className="min-h-full flex flex-col bg-neutral justify-between w-full p-2 md:p-12 lg:px-20"
      >
        <div className="w-full text-center">
          <h1 className="text-neutral-content mb-2 text-2xl md:text-3xl lg:text-4xl">
            {t('projects.id')}
          </h1>
        </div>
        <Card
          id="projects-photoautomat"
          className="mx-auto"
          imageSrc={require('assets/photoautomat.png')}
          title={t('projects.photoautomat.title')}
          content={t('projects.photoautomat.content')}
        />
        <div className="h-20"></div>
      </div>
      <Devider />
      <div
        id="education"
        className="min-h-full flex flex-col bg-neutral justify-between w-full p-2 md:p-12 lg:px-20 pb-0 md:pb-0"
      >
        <div className="w-full text-center">
          <h1 className="text-neutral-content mb-2 text-2xl md:text-3xl lg:text-4xl">
            {t('education.id')}
          </h1>
        </div>
        <Card
          id="education-kdg"
          className="mx-auto"
          isOpenByDefault
          imageSrc={require('assets/kdg.jpeg')}
          title={t('education.kdg.title')}
          titleAccent={t('education.kdg.titleAccent')}
          description={t('education.kdg.description')}
          content={t('education.kdg.content')}
        />
        <Credit />
      </div>
    </>
  )
}
