import { FC } from 'react'
import { CopyRight } from 'components/base/CopyRight'

export const Credit: FC = () => (
  <footer className="w-full flex justify-center bg-neutral">
    <div className="bg-[#303030] rounded py-2 px-4 m-2 relative">
      <CopyRight openSourceRepository="https://gitlab.com/brecht.p/brecht-frontend" />
    </div>
  </footer>
)
