interface Window {
  config: {
    REACT_APP_BACKEND_URL: string
    REACT_APP_ENVIRONMENT: string
  }
}
declare const window: Window

export const isDev = process.env.ENVIRONMENT !== 'production'

const BACKEND_URL = isDev
  ? process.env.REACT_APP_BACKEND_URL
  : window.config.REACT_APP_BACKEND_URL

const ENVIRONMENT = isDev
  ? process.env.REACT_APP_ENVIRONMENT
  : window.config.REACT_APP_ENVIRONMENT

export const env = {
  BACKEND_URL,
  ENVIRONMENT,
}
